:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(8, 8, 8, 0.87);
  background-color: #ffffff;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.test{
  margin-top: 50px;
  margin: auto;
  width: 60%;  
}
@media screen and (max-width: 1200px) {
  .test {
    margin-top: 50px;
    margin: auto;
    width: 100%;
  }
}

.test2 {
  margin-left: 100px;
}

@media screen and (max-width: 1200px) {
  .test2 {
    margin-left: 50px;
  }
}